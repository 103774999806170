import anton from '../../../source/images/main/anton.jpg'
import alisa from '../../../source/images/main/alisa.jpg'
import ksenia from '../../../source/images/main/ksenia.jpg'
import alina from '../../../source/images/main/alina.jpg'
import islands1 from '../../../source/images/main/islands-150.jpg'
import islands2 from '../../../source/images/main/islands1501.jpg'
import islands3 from '../../../source/images/main/islands1502.jpg'
import islands4 from '../../../source/images/main/islands1503.jpg'
import islands5 from '../../../source/images/main/islands1504.jpg'


export const ReviewsData = [
    {
        img: islands1,
        nameRew: 'Ирина Абрамян',
        headReviews: 'Сын ходит в эту школу. Очень нравится.',
        reviews: 'Занятия интересные,преподаватель подходит творчески и основательно! Скоро будем читать и говорить на английском языке! Немаловажна локация школы! Спасибо, что открылись в этом районе!'

    },
    {
        img: alina,
        nameRew: 'Alina Nazarova',
        headReviews: 'Мы с удовольствием посещаем занятия английского языка.',
        reviews: 'С первых уроков дети говорят на английском языке. Для меня было удивительно, когда дети вышли с занятий и приветствовали своих сверстников на английском языке. Дома тоже в играх произносит слова на английском. Поем песни на английском которые легко запоминаются, пишем слова в словарик новые.'

    },
    {
        img: alisa,
        nameRew: 'Алиса',
        headReviews: 'Ребенку нравятся занятия, ходит с удовольствием.',
        reviews: 'Хотя сначала очень не хотел, и думал что это неинтересно и ему не надо) Педагог смог вовлечь в процесс обучения и это главное👍 Спасибо! Очень нравится что есть отдельные мастер классы для взрослых и детей, молодцы!'

    },
    {
        img: islands3,
        nameRew: 'Евгения Степан',
        headReviews: 'Дочка начала ходить на Английский, на Земской к Джамиле, и оочень доврльна.',
        reviews: 'Педагог прекрасно и доступно дает материал, что даже детки которые раньше не изучали английский, уже на ходу могут составить преложения и перевести его. Очень благодарна пелагогам, так держать ✊'

    },
    {
        img: islands2,
        nameRew: 'Дмитрий',
        headReviews: 'MaryPro — место, где действительно учат языку.',
        reviews: 'Здесь не будет легко ни ребенку, ни взрослому, но здесь будет гарантированный результат. Мария Павловна — сильнейший преподаватель. Занимаюсь итальянским всего год и уже могу разговаривать на базовом уровне и понимать других людей.'

    },
    {
        img: ksenia,
        nameRew: 'Ксения Кузнецова',
        headReviews: 'Рекомендую!',
        reviews: 'Очень хорошая Языковая школа! Сильнейший преподовательский состав! Занимаемся у Марии, ребенку очень нравится - в игровой форме все доступно объясняют и он с удовольствием ходит на занятия. И не менее важное для меня это доступное расположение - можно доехать без пробок и удобно припарковаться.'

    },
    {
        img: islands4,
        nameRew: 'Мишина Анжела',
        headReviews: 'Отличная школа.',
        reviews: 'Дети идут за знаниями с огромной радостью.'

    },
    {
        img: anton,
        nameRew: 'Антон Харитонов',
        headReviews: 'Был на открытии, знаю педагогов очень хорошо.',
        reviews: 'Рекомендовал их раньше, а с открытием школы рекомендовать буду вдвое чаще и втрое напористее - лучше пока никого не видел. Учат с нуля, помогают сдавать нужные экзамены, расширяют кругозор в принципе! Удачи на новом месте)'

    },
    {
        img: islands5,
        nameRew: 'Кристина Бредихина',
        headReviews: 'Великолепно!',
        reviews: 'Приемлимые цены за хорошие занятия'

    },
]